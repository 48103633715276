body {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-padding, .navbar, .project-body, .contact-link, .site-title, .horizontal-line {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.intro {
  margin-top: 100px;
}

.navbar {
    display: flex; 
    justify-content: center; 
    list-style: none; 
  }
  
.navbar li {
    margin: 0 10px; 
  }

.navbar-link {
    text-decoration: none; 
    color: black;
  }

.site-title { 
    display: flex; 
    justify-content: center; 
    text-decoration: none; 
    color: black;
    font-weight: bold;
    font-size: 35px;
    text-align: center;
  }

.contact-link {
    text-decoration: none;
    font-weight: bold;
    font-size: 18px; 
  }

.page-padding {
    padding-left: 280px;
    padding-top: 20px;
    padding-right: 280px;
}

@media (max-width: 768px) {
  .page-padding {
    padding-left: 40px;
    padding-top: 20px;
    padding-right: 40px;
  }
}

.page-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.link-text {
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
}

.company-display {
  display: inline-block;
}

@media (max-width: 768px) {
  .role-display {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.role-display{
    font-style: italic;
}

.company-padding {
    padding: 10px;
}

@media (max-width: 768px) {
  .company-padding{
    padding: 0px;
  }
}

::selection {
    background-color: rgb(241, 80, 5);
    color: rgb(0, 0, 0); 
  }

.project-body {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  min-height: '100vh';
  margin-top: 30px;
  margin-right: 50px;
  top: 50%;
  left: 50%;
  text-align: left;
}

.techStack {
  font-style: italic;
  font-size: 15px;
  margin-top: 0px;
}

.projectTitle {
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 768px) {
  .projectTitle {
    font-size: 20px; 
  }
}

[data-theme='dark'] .projectTitle {
  color: white;
}

.project-img {
  height: 300px;
  width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .project-img {
    width: 100%;
    height: 70%;
  }
}

.contacts {
  margin-top: 50px;
}

h2 {
  margin-right: 20px; 
}

.horizontal-line {
  height: 5px;
  flex-grow: 1;
  transition: background-color 1s;
}

.logo {
  height: 50px;
  width: 100px;
}

/* Default styles for links */
a {
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Styles for links in dark mode */
[data-theme='dark'] a {
  color: white;
}

/* Specific classes if needed */
.contact-link, .link-text {
  transition: color 0.3s ease; /* Ensure smooth transition */
}

/* Ensure dark mode styling for specific link classes */
[data-theme='dark'] .contact-link,
[data-theme='dark'] .link-text {
  color: white;
}
.contact-icons {
  display: flex;
  justify-content: flex-start; /* Align icons to the left */
  gap: 20px; /* Space between each icon */
  margin-top: 20px;
}

.contact-icon {
  font-size: 40px; /* Adjust size of icons */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.contact-link {
  text-decoration: none;
}

/* Dark mode styles */
[data-theme='dark'] .contact-icon {
  color: white !important;
}

.intro p {
  margin-bottom: 10px;
}

.intro h5 {
  margin-bottom: 15px;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Dark mode style */
[data-theme='dark'] .svg-element {
  fill: white;
  stroke: white; 
}

.footer {
  display: flex;
  justify-content: flex-end; /* Align the icons to the right */
  padding: 20px;
  background-color: transparent; /* Remove background color to make it blend */
  position: fixed; /* Fix the footer to the side of the screen */
  right: 0;
  bottom: 0;
}

.footer-link {
  margin: 0 10px; /* Space between icons */
  text-decoration: none;
}

.footer-icon {
  font-size: 20px; /* Smaller size for the footer icons */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Dark mode styles for footer icons */
[data-theme='dark'] .footer-icon {
  color: white; /* Change icon color to white in dark mode */
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.svg-element {
    will-change: transform;  /* Helps with performance */
    transform: translateZ(0);  /* Forces GPU acceleration */
}

.svg-drawing {
    opacity: 0;
    animation: fadeIn 0.3s ease-in forwards;
    will-change: opacity;
}

.svg-drawing path {
    stroke: var(--text-color);
    fill: none;
    stroke-width: 2;
    will-change: stroke-dashoffset;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes drawPath {
    to {
        stroke-dashoffset: 0;
    }
}
